import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Modal,
} from '@mui/material';
import { CheckCircle, Cancel, Visibility } from '@mui/icons-material';

interface Evaluation {
  id: number;
  user_id: string;
  answers: Record<string, string>;
  email: string;
  type: 'peer' | 'leader';
}

interface User {
  nome: string;
  email: string;
  user_id: string;
  completed_peer_evaluation: boolean;
  completed_leader_evaluation: boolean;
}

const Metrics: React.FC = () => {
  const [evaluations, setEvaluations] = useState<Evaluation[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const { data: usersData, error: usersError } = await supabase
          .from('app_users')
          .select('nome, email, user_id, completed_peer_evaluation, completed_leader_evaluation');

        if (usersError) {
          console.error('Error fetching users:', usersError);
          return;
        }

        setUsers(usersData);

        const { data: evaluationsData, error: evaluationsError } = await supabase
          .from('evaluations')
          .select('*');

        if (evaluationsError) {
          console.error('Error fetching evaluations:', evaluationsError);
          return;
        }

        setEvaluations(evaluationsData);
      } catch (error) {
        console.error('Unexpected error:', error);
      }
    };

    fetchMetrics();
  }, []);

  const handleOpenModal = (user: User) => {
    setSelectedUser(user);
  };

  const handleCloseModal = () => {
    setSelectedUser(null);
  };

  return (
    <Container>
      <Box mt={4}>
        <Typography variant="h5">Status das Avaliações</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Usuário</TableCell>
                <TableCell>Avaliação de Pares</TableCell>
                <TableCell>Avaliação de Liderança</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, index) => (
                <TableRow key={user.user_id || index}>
                  <TableCell>{user.nome}</TableCell>
                  <TableCell style={{ color: user.completed_peer_evaluation ? 'green' : 'red' }}>
                    {user.completed_peer_evaluation ? (
                      <Box display="flex" alignItems="center">
                        <CheckCircle style={{ marginRight: 4 }} />
                        Concluído
                      </Box>
                    ) : (
                      <Box display="flex" alignItems="center">
                        <Cancel style={{ marginRight: 4 }} />
                        Pendente
                      </Box>
                    )}
                  </TableCell>
                  <TableCell style={{ color: user.completed_leader_evaluation ? 'green' : 'red' }}>
                    {user.completed_leader_evaluation ? (
                      <Box display="flex" alignItems="center">
                        <CheckCircle style={{ marginRight: 4 }} />
                        Concluído
                      </Box>
                    ) : (
                      <Box display="flex" alignItems="center">
                        <Cancel style={{ marginRight: 4 }} />
                        Pendente
                      </Box>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Button onClick={() => handleOpenModal(user)} startIcon={<Visibility />}>
                      Ver Avaliações
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Modal open={!!selectedUser} onClose={handleCloseModal}>
        <Box
          component="div"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            backgroundColor: 'white',
            padding: 16,
            boxShadow: '0px 3px 6px #00000029',
            overflowY: 'scroll',
            maxHeight: '80vh',
          }}
        >
          {selectedUser && (
            <>
              <Typography variant="h6">Avaliações Recebidas por {selectedUser.nome}</Typography>
              <Box mt={2}>
                {evaluations
                  .filter(evaluation => evaluation.user_id === selectedUser.user_id)
                  .map((evaluation, index) => (
                    <Box key={evaluation.id}>
                      <Typography variant="body1">Avaliação {index + 1} ({evaluation.type})</Typography>
                      <Typography variant="body2">
                        Respostas: {JSON.stringify(evaluation.answers, null, 2)}
                      </Typography>
                    </Box>
                  ))}
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </Container>
  );
};

export default Metrics;
