import React, { createContext, useState, useContext, ReactNode } from 'react';

interface EvaluationContextProps {
  completedPeers: boolean;
  completedLeaders: boolean;
  setCompletedPeers: (value: boolean) => void;
  setCompletedLeaders: (value: boolean) => void;
}

const EvaluationContext = createContext<EvaluationContextProps | undefined>(undefined);

export const EvaluationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [completedPeers, setCompletedPeers] = useState<boolean>(false);
  const [completedLeaders, setCompletedLeaders] = useState<boolean>(false);

  return (
    <EvaluationContext.Provider value={{ completedPeers, completedLeaders, setCompletedPeers, setCompletedLeaders }}>
      {children}
    </EvaluationContext.Provider>
  );
};

export const useEvaluation = () => {
  const context = useContext(EvaluationContext);
  if (!context) {
    throw new Error('useEvaluation must be used within an EvaluationProvider');
  }
  return context;
};
