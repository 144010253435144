import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, TextField, SelectChangeEvent } from '@mui/material';

interface QuestionProps {
  questionKey: string;
  questionLabel: string;
  value: string;
  onChange: (e: SelectChangeEvent<string>, questionKey: string) => void;
}

const EvaluationQuestion: React.FC<QuestionProps> = ({ questionKey, questionLabel, value, onChange }) => (
  <FormControl fullWidth variant="outlined" margin="normal">
    <InputLabel>{questionLabel}</InputLabel>
    <Select
      value={value}
      onChange={(e) => onChange(e, questionKey)}
      label={questionLabel}
    >
      <MenuItem value="Supera">Supera</MenuItem>
      <MenuItem value="Atende">Atende</MenuItem>
      <MenuItem value="Atende parcialmente">Atende parcialmente</MenuItem>
      <MenuItem value="Não atende">Não atende</MenuItem>
    </Select>
  </FormControl>
);

interface EvaluationQuestionsProps {
  answers: Record<string, string>;
  onChange: (e: SelectChangeEvent<string>, questionKey: string) => void;
}

const EvaluationQuestions: React.FC<EvaluationQuestionsProps> = ({ answers, onChange }) => {
  const questions = [
    { key: 'teamRelationship', label: 'Relacionamento com o time' },
    { key: 'leadershipRelationship', label: 'Relacionamento com as lideranças' },
    { key: 'technicalKnowledge', label: 'Conhecimento técnico' },
    { key: 'taskEfficiency', label: 'Eficiência na execução das tarefas' },
    { key: 'taskResponsibility', label: 'Responsabilidade com suas tarefas' },
    { key: 'companyCommitment', label: 'Comprometimento com a empresa' },
    { key: 'teamGrowthContribution', label: 'Contribuição no crescimento (evolução) do time' },
  ];

  return (
    <>
      {questions.map((question) => (
        <EvaluationQuestion
          key={question.key}
          questionKey={question.key}
          questionLabel={question.label}
          value={answers[question.key] || ''}
          onChange={onChange}
        />
      ))}
      <FormControl fullWidth variant="outlined" margin="normal">
        <TextField
          label="Comentários/Sugestões"
          multiline
          rows={4}
          value={answers['comments'] || ''}
          onChange={(e) => onChange({ target: { value: e.target.value } } as SelectChangeEvent<string>, 'comments')}
        />
      </FormControl>
    </>
  );
};

export default EvaluationQuestions;
