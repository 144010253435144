import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { Container, TextField, Button, Typography, Box, CircularProgress, Snackbar, Alert, Card, CardContent, Link, IconButton, InputAdornment } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);
    const { data, error } = await supabase.auth.signInWithPassword({ email, password });
    setLoading(false);

    if (error) {
      if (error.message.toLowerCase().includes('invalid login credentials')) {
        setSnackbarMessage('Email ou senha incorretos.');
      } else {
        setSnackbarMessage('Falha ao fazer login.');
      }
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    const { user } = data;
    if (user) {
      const { data: userData, error: userError } = await supabase.from('app_users').select('id').eq('email', email).single();
      if (userError || !userData) {
        setSnackbarMessage('Usuário não autorizado');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        await supabase.auth.signOut();
        return;
      }
      setSnackbarMessage('Login realizado com sucesso');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      navigate('/');
    }
  };

  return (
    <Container maxWidth="xs">
      <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
        <Card>
          <CardContent>
            <Typography variant="h4" component="h1" gutterBottom>
              Login
            </Typography>
            <TextField
              label="Email"
              variant="outlined"
              margin="normal"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{ startAdornment: <EmailIcon /> }}
            />
            <TextField
              label="Senha"
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              margin="normal"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                startAdornment: <LockIcon />,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Button variant="contained" color="primary" fullWidth onClick={handleLogin} sx={{ mt: 2 }} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Login'}
            </Button>
            <Box mt={2}>
              <Typography>
                Primeiro acesso? <Link component={RouterLink} to="/register">Cadastre-se</Link>
              </Typography>
              <Typography>
                <Link component={RouterLink} to="/forgot-password">Esqueci a senha</Link>
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Login;
