import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Card, CardContent, Typography, Button } from '@mui/material';
import { People, Leaderboard } from '@mui/icons-material';
import { useEvaluation } from '../contexts/EvaluationContext';

const EvaluationSelection: React.FC = () => {
  const navigate = useNavigate();
  const { completedPeers, completedLeaders } = useEvaluation();

  const handleNavigate = (evaluationType: 'peer' | 'leader') => {
    if (window.confirm("Você está prestes a iniciar uma avaliação. Não saia durante a avaliação para garantir o anonimato. Deseja continuar?")) {
      navigate(`/evaluation/${evaluationType}`);
    }
  };

  return (
    <Container>
      <Box display="flex" justifyContent="space-around" mt={4}>
        <Card>
          <CardContent>
            <People fontSize="large" />
            <Typography variant="h5" component="div">
              Avaliação de Pares
            </Typography>
            {completedPeers ? (
              <Typography variant="body2" color="text.secondary">
                Concluído
              </Typography>
            ) : (
              <Button variant="contained" color="primary" onClick={() => handleNavigate('peer')}>
                Iniciar Avaliação
              </Button>
            )}
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Leaderboard fontSize="large" />
            <Typography variant="h5" component="div">
              Avaliação de Liderança
            </Typography>
            {completedLeaders ? (
              <Typography variant="body2" color="text.secondary">
                Concluído
              </Typography>
            ) : (
              <Button variant="contained" color="primary" onClick={() => handleNavigate('leader')}>
                Iniciar Avaliação
              </Button>
            )}
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default EvaluationSelection;
