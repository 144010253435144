import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, TextField, SelectChangeEvent } from '@mui/material';

interface QuestionProps {
  questionKey: string;
  questionLabel: string;
  value: string;
  onChange: (e: SelectChangeEvent<string>, questionKey: string) => void;
}

const EvaluationQuestion: React.FC<QuestionProps> = ({ questionKey, questionLabel, value, onChange }) => (
  <FormControl fullWidth variant="outlined" margin="normal">
    <InputLabel>{questionLabel}</InputLabel>
    <Select
      value={value}
      onChange={(e) => onChange(e, questionKey)}
      label={questionLabel}
    >
      <MenuItem value="Supera">Supera</MenuItem>
      <MenuItem value="Atende">Atende</MenuItem>
      <MenuItem value="Atende parcialmente">Atende parcialmente</MenuItem>
      <MenuItem value="Não atende">Não atende</MenuItem>
    </Select>
  </FormControl>
);

interface LeadershipEvaluationQuestionsProps {
  answers: Record<string, string>;
  onChange: (e: SelectChangeEvent<string>, questionKey: string) => void;
}

const LeadershipEvaluationQuestions: React.FC<LeadershipEvaluationQuestionsProps> = ({ answers, onChange }) => {
  const questions = [
    { key: 'teamUnity', label: 'Você acredita que seu líder facilita a união e a colaboração do time?' },
    { key: 'communication', label: 'Qual seu nível de satisfação com a comunicação do seu líder? Há clareza?' },
    { key: 'problemSolving', label: 'Você sente que pode contar com seu líder para a resolução de problemas cotidianos?' },
    { key: 'personalDevelopment', label: 'Seu líder contribui para o seu desenvolvimento pessoal/profissional?' },
    { key: 'technicalCapability', label: 'Você considera o seu líder tecnicamente capacitado para gerenciar o time?' },
    { key: 'transparency', label: 'Você acredita que seu líder seja transparente em relação aos objetivos da área?' },
  ];

  return (
    <>
      {questions.map((question) => (
        <EvaluationQuestion
          key={question.key}
          questionKey={question.key}
          questionLabel={question.label}
          value={answers[question.key] || ''}
          onChange={onChange}
        />
      ))}
      <FormControl fullWidth variant="outlined" margin="normal">
        <TextField
          label="Comentários/Sugestões"
          multiline
          rows={4}
          value={answers['comments'] || ''}
          onChange={(e) => onChange({ target: { value: e.target.value } } as SelectChangeEvent<string>, 'comments')}
        />
      </FormControl>
    </>
  );
};

export default LeadershipEvaluationQuestions;
