import React, { useState, useEffect, FormEvent } from 'react';
import { Container, Typography, Button, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Snackbar, Alert, Checkbox, FormControlLabel } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { supabase } from '../supabaseClient';

interface User {
  id: number;
  nome: string;
  email: string;
  user_id: string;
  role: string;
  squad: string[];
  setor: string[];
  empresa: string[];
  hierarquia: string;
  available_for_peer_assessment: boolean;
  available_for_leadership_assessment: boolean;
}

const UserManagement: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [open, setOpen] = useState(false);
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const { data, error } = await supabase.from('app_users').select('*');
    if (error) {
      console.error('Error fetching users:', error);
    } else {
      setUsers(data as User[]);
    }
  };

  const handleOpen = (user: User | null = null) => {
    setEditingUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingUser(null);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const user = {
      nome: formData.get('nome') as string,
      email: formData.get('email') as string,
      role: formData.get('role') as string,
      hierarquia: formData.get('hierarquia') as string,
      squad: (formData.get('squad') as string).split(',').map(s => s.trim()),
      setor: (formData.get('setor') as string).split(',').map(s => s.trim()),
      empresa: (formData.get('empresa') as string).split(',').map(s => s.trim()),
      available_for_peer_assessment: formData.get('available_for_peer_assessment') === 'true',
      available_for_leadership_assessment: formData.get('available_for_leadership_assessment') === 'true'
    };

    if (editingUser) {
      // Update user
      const { error } = await supabase.from('app_users').update(user).eq('id', editingUser.id);
      if (error) {
        setSnackbarMessage('Erro ao atualizar usuário.');
        setSnackbarSeverity('error');
      } else {
        setSnackbarMessage('Usuário atualizado com sucesso.');
        setSnackbarSeverity('success');
      }
    } else {
      // Create user
      const { error } = await supabase.from('app_users').insert(user);
      if (error) {
        setSnackbarMessage('Erro ao criar usuário.');
        setSnackbarSeverity('error');
      } else {
        setSnackbarMessage('Usuário criado com sucesso.');
        setSnackbarSeverity('success');
      }
    }

    setSnackbarOpen(true);
    handleClose();
    fetchUsers();
  };

  const handleDelete = async (id: number) => {
    const { error } = await supabase.from('app_users').delete().eq('id', id);
    if (error) {
      setSnackbarMessage('Erro ao excluir usuário.');
      setSnackbarSeverity('error');
    } else {
      setSnackbarMessage('Usuário excluído com sucesso.');
      setSnackbarSeverity('success');
    }

    setSnackbarOpen(true);
    fetchUsers();
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Gerenciamento de Usuários
      </Typography>
      <Button variant="contained" color="primary" onClick={() => handleOpen()}>
        Adicionar Usuário
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Hierarquia</TableCell>
            <TableCell>Squad</TableCell>
            <TableCell>Setor</TableCell>
            <TableCell>Empresa</TableCell>
            <TableCell>Peer Assessment</TableCell>
            <TableCell>Leadership Assessment</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.nome}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.role}</TableCell>
              <TableCell>{user.hierarquia}</TableCell>
              <TableCell>{user.squad.join(', ')}</TableCell>
              <TableCell>{user.setor.join(', ')}</TableCell>
              <TableCell>{user.empresa.join(', ')}</TableCell>
              <TableCell>{user.available_for_peer_assessment ? 'Sim' : 'Não'}</TableCell>
              <TableCell>{user.available_for_leadership_assessment ? 'Sim' : 'Não'}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleOpen(user)}>
                  <Edit />
                </IconButton>
                <IconButton onClick={() => handleDelete(user.id)}>
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editingUser ? 'Editar Usuário' : 'Adicionar Usuário'}</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="nome"
              label="Nome"
              type="text"
              fullWidth
              defaultValue={editingUser ? editingUser.nome : ''}
              required
            />
            <TextField
              margin="dense"
              name="email"
              label="Email"
              type="email"
              fullWidth
              defaultValue={editingUser ? editingUser.email : ''}
              required
            />
            <TextField
              margin="dense"
              name="role"
              label="Role"
              type="text"
              fullWidth
              defaultValue={editingUser ? editingUser.role : 'user'}
              required
            />
            <TextField
              margin="dense"
              name="hierarquia"
              label="Hierarquia"
              type="text"
              fullWidth
              defaultValue={editingUser ? editingUser.hierarquia : 'OP'}
              required
            />
            <TextField
              margin="dense"
              name="squad"
              label="Squad"
              type="text"
              fullWidth
              defaultValue={editingUser ? editingUser.squad.join(', ') : ''}
            />
            <TextField
              margin="dense"
              name="setor"
              label="Setor"
              type="text"
              fullWidth
              defaultValue={editingUser ? editingUser.setor.join(', ') : ''}
            />
            <TextField
              margin="dense"
              name="empresa"
              label="Empresa"
              type="text"
              fullWidth
              defaultValue={editingUser ? editingUser.empresa.join(', ') : ''}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="available_for_peer_assessment"
                  defaultChecked={editingUser ? editingUser.available_for_peer_assessment : true}
                />
              }
              label="Disponível para Avaliação de Pares"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="available_for_leadership_assessment"
                  defaultChecked={editingUser ? editingUser.available_for_leadership_assessment : true}
                />
              }
              label="Disponível para Avaliação de Liderança"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              {editingUser ? 'Salvar' : 'Adicionar'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default UserManagement;
