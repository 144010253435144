import React from 'react';
import { Box, Typography, Chip, Avatar } from '@mui/material';
import { CheckCircle, Assignment } from '@mui/icons-material';
import { User } from './EvaluationForm';

interface SelectEvaluateeProps {
  users: User[];
  onSelect: (user: User) => void;
  completed: Set<string>;
}

const SelectEvaluatee: React.FC<SelectEvaluateeProps> = ({ users, onSelect, completed }) => {
  return (
    <Box>
      <Typography variant="h5" component="h2" gutterBottom>
        Selecione o colega para avaliar
      </Typography>
      <Box mt={2}>
        {users.map((user) => {
          const key = user.user_id || user.email;
          return (
            <Box key={key} display="flex" alignItems="center" mb={2} justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <Avatar alt={user.nome} src="/static/images/avatar/1.jpg" />
                <Box ml={2}>
                  <Typography variant="body1">{user.nome}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Squads: {user.squad.join(', ')}
                  </Typography>
                </Box>
              </Box>
              <Chip
                label={completed.has(key) ? 'Avaliado' : 'Avaliar'}
                icon={completed.has(key) ? <CheckCircle /> : <Assignment />}
                color={completed.has(key) ? 'success' : 'default'}
                onClick={() => onSelect(user)}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default SelectEvaluatee;
