import React from 'react';
import { Box, Typography, Chip, Avatar } from '@mui/material';
import { CheckCircle, Assignment } from '@mui/icons-material';
import { User } from './EvaluationForm';

interface SelectLeaderProps {
  leaders: User[];
  onSelect: (user: User) => void;
  completed: Set<string>;
}

const SelectLeader: React.FC<SelectLeaderProps> = ({ leaders, onSelect, completed }) => {
  return (
    <Box>
      <Typography variant="h5" component="h2" gutterBottom>
        Selecione o líder para avaliar
      </Typography>
      <Box mt={2}>
        {leaders.map((leader) => {
          const key = leader.user_id || leader.email;
          return (
            <Box key={key} display="flex" alignItems="center" mb={2} justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <Avatar alt={leader.nome} src="/static/images/avatar/1.jpg" />
                <Box ml={2}>
                  <Typography variant="body1">{leader.nome}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Squads: {leader.squad.join(', ')}
                  </Typography>
                </Box>
              </Box>
              <Chip
                label={completed.has(key) ? 'Avaliado' : 'Avaliar'}
                icon={completed.has(key) ? <CheckCircle /> : <Assignment />}
                color={completed.has(key) ? 'success' : 'default'}
                onClick={() => onSelect(leader)}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default SelectLeader;
