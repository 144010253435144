import React, { useState, useRef } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  CircularProgress
} from '@mui/material';
import { ExitToApp, Home, Dashboard, Brightness4, Brightness7, Group, BugReport } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import { useColorMode } from '../theme';
import emailjs from '@emailjs/browser';

emailjs.init('0O7Bo8K5dEdLrSrR-'); // Inicialização do EmailJS com a chave pública

interface NavbarProps {
  session: any;
  isAdmin: boolean;
  onLogout: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ session, isAdmin, onLogout }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openBugReport, setOpenBugReport] = useState(false);
  const [bugMessage, setBugMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { toggleColorMode } = useColorMode();
  const formRef = useRef<HTMLFormElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleBugReportOpen = () => {
    setOpenBugReport(true);
  };

  const handleBugReportClose = () => {
    setOpenBugReport(false);
  };

  const handleBugMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBugMessage(event.target.value);
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    const date = now.toLocaleDateString();
    const time = now.toLocaleTimeString();
    return { date, time };
  };

  const getSupabaseResponseLog = () => {
    return 'Exemplo de resposta do Supabase';
  };

  const sendBugReport = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    if (!formRef.current) {
      console.error('O formulário não está disponível');
      setLoading(false);
      return;
    }

    try {
      const user = session?.user;
      const userEmail = user?.email || 'Email não disponível';
      const userId = user?.id || 'ID não disponível';
      const userRole = isAdmin ? 'Admin' : 'User';

      const { date, time } = getCurrentDateTime();
      const supabaseResponseLog = getSupabaseResponseLog();
      const reactErrorLog = 'Exemplo de log de erros do React';

      const templateParams = {
        message: bugMessage,
        user_email: userEmail,
        user_id: userId,
        user_role: userRole,
        current_date: date,
        current_time: time,
        supabase_response: supabaseResponseLog,
        react_error_log: reactErrorLog,
      };

      const response = await emailjs.send(
        'service_aabiiny', 
        'template_hv4bv2n', 
        templateParams
      );

      console.log('EmailJS response:', response);
      setBugMessage('');
      setOpenBugReport(false);
      setLoading(false);
    } catch (error: any) {
      console.error('Erro ao enviar o relatório de bug:', error);
      setLoading(false);
    }
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Avaliação Interna
        </Typography>
        <IconButton color="inherit" onClick={toggleColorMode}>
          {localStorage.getItem('theme') === 'dark' ? <Brightness7 /> : <Brightness4 />}
        </IconButton>
        {session && (
          <Box display="flex" alignItems="center">
            <Button color="inherit" component={RouterLink} to="/">
              <Home />
              Home
            </Button>
            {isAdmin && (
              <>
                <Button color="inherit" component={RouterLink} to="/metrics">
                  <Dashboard />
                  Metrics
                </Button>
                <Button color="inherit" component={RouterLink} to="/user-management">
                  <Group />
                  Usuários
                </Button>
              </>
            )}
            <IconButton color="inherit" onClick={handleBugReportOpen}>
              <BugReport />
            </IconButton>
            <IconButton color="inherit" onClick={handleMenuOpen}>
              <Avatar alt="User Avatar" src="/static/images/avatar/1.jpg" />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem component={RouterLink} to="/profile">Perfil</MenuItem>
              <MenuItem onClick={onLogout}>
                <ExitToApp />
                Sair
              </MenuItem>
            </Menu>
          </Box>
        )}
      </Toolbar>
      <Dialog open={openBugReport} onClose={handleBugReportClose}>
        <DialogTitle>Reportar Bug</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Por favor, descreva o problema que você está enfrentando. As informações de log serão enviadas automaticamente.
          </DialogContentText>
          <form ref={formRef} onSubmit={sendBugReport}>
            <TextField
              autoFocus
              margin="dense"
              label="Descrição do Bug"
              type="text"
              fullWidth
              value={bugMessage}
              onChange={handleBugMessageChange}
              name="message"
            />
            <DialogActions>
              <Button onClick={handleBugReportClose} color="primary">
                Cancelar
              </Button>
              <Button type="submit" color="primary" disabled={loading}>
                {loading ? <CircularProgress size={24} /> : 'Enviar'}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </AppBar>
  );
};

export default Navbar;
