import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import { Container, TextField, Button, Typography, Box, CircularProgress, Snackbar, Alert, Card, CardContent, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const ForgotPassword: React.FC = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

    const handlePasswordReset = async () => {
        setLoading(true);
        const { error } = await supabase.auth.resetPasswordForEmail(email, {
            redirectTo: 'https://rh.grupogx.com.br/reset-password'
        });
        setLoading(false);

        if (error) {
            setSnackbarMessage('Erro ao enviar email de redefinição de senha');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }

        setSnackbarMessage('Email de redefinição de senha enviado com sucesso');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
    };


    return (
        <Container maxWidth="xs">
            <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
                <Card>
                    <CardContent>
                        <Typography variant="h4" component="h1" gutterBottom>
                            Esqueci a Senha
                        </Typography>
                        <TextField
                            label="Email"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <Button variant="contained" color="primary" fullWidth onClick={handlePasswordReset} sx={{ mt: 2 }} disabled={loading}>
                            {loading ? <CircularProgress size={24} /> : 'Enviar'}
                        </Button>
                        <Box mt={2}>
                            <Typography>
                                <Link component={RouterLink} to="/login">Voltar para login</Link>
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default ForgotPassword;
