import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import {
  Container,
  Typography,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  SelectChangeEvent,
  Modal,
  Paper,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SelectEvaluatee from './SelectEvaluatee';
import SelectLeader from './SelectLeader';
import EvaluationQuestions from './EvaluationQuestions';
import LeadershipEvaluationQuestions from './LeadershipEvaluationQuestions';
import { useEvaluation } from '../contexts/EvaluationContext';

export interface User {
  nome: string;
  email: string;
  squad: string[];
  user_id?: string;
  role: string;
  setor: string[];
  empresa: string[];
  hierarquia: string;
  available_for_peer_assessment?: boolean;
  available_for_leadership_assessment?: boolean;
}

const EvaluationForm: React.FC = () => {
  const { type } = useParams<{ type: 'peer' | 'leader' }>();
  const [users, setUsers] = useState<User[]>([]);
  const [leaders, setLeaders] = useState<User[]>([]);
  const [squads, setSquads] = useState<string[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [answers, setAnswers] = useState<Record<string, string>>({});
  const [completed, setCompleted] = useState<Set<string>>(new Set());
  const [pendingEvaluations, setPendingEvaluations] = useState<any[]>([]);
  const [evaluatorId, setEvaluatorId] = useState<string | null>(null);
  const [isEvaluationPeriodActive, setIsEvaluationPeriodActive] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { setCompletedPeers, setCompletedLeaders } = useEvaluation();

  const fetchUserDetails = async () => {
    const { data: sessionData, error: sessionError } = await supabase.auth.getSession();
    if (sessionError) {
      console.error('Error fetching session:', sessionError);
      return;
    }

    const session = sessionData?.session;
    if (session) {
      setEvaluatorId(session.user.id);

      const { data: userData, error: userError } = await supabase
        .from('app_users')
        .select('squad, role, setor, empresa, hierarquia')
        .eq('user_id', session.user.id)
        .single();

      if (userError) {
        console.error('Error fetching user data:', userError);
        return;
      }

      if (userData) {
        const squadArray = userData.squad || [];
        setSquads(squadArray);

        const squadUsersMap = new Map<string, User>();

        for (const squad of squadArray) {
          const { data, error } = await supabase
            .from('app_users')
            .select('*')
            .contains('squad', [squad])
            .eq('available_for_peer_assessment', true);

          if (error) {
            console.error('Error fetching squad users:', error);
            return;
          }

          if (data) {
            data.forEach((user: User) => {
              const uniqueKey = user.user_id || user.email;
              if (uniqueKey !== session.user.id) {
                squadUsersMap.set(uniqueKey, user);
              }
            });
          }
        }

        const { data: leaderData, error: leaderError } = await supabase
          .rpc('get_leaders', { p_user_id: session.user.id });

        if (leaderError) {
          console.error('Error fetching leaders:', leaderError);
          return;
        }

        const filteredLeaders = leaderData.filter((leader: User) => leader.available_for_leadership_assessment);

        setUsers(Array.from(squadUsersMap.values()));
        setLeaders(filteredLeaders);

        const { data: periodData, error: periodError } = await supabase
          .from('evaluation_periods')
          .select('*')
          .eq('is_active', true)
          .single();

        if (periodError) {
          console.error('Error fetching evaluation period:', periodError);
          return;
        }

        if (periodData) {
          setIsEvaluationPeriodActive(
            new Date() >= new Date(periodData.start_date) && new Date() <= new Date(periodData.end_date)
          );
        }
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserDetails();
  }, [type]);

  const handleQuestionChange = (e: SelectChangeEvent<string>, questionKey: string) => {
    if (selectedUser) {
      setAnswers({ ...answers, [questionKey]: e.target.value });
    }
  };

  const handleSubmitEvaluation = async () => {
    try {
      const { error } = await supabase.from('evaluations').insert(pendingEvaluations);
      if (error) throw error;

      const { error: statusError } = await supabase
        .from('app_users')
        .update(type === 'peer' ? { completed_peer_evaluation: true } : { completed_leader_evaluation: true })
        .eq('user_id', evaluatorId);
      if (statusError) throw statusError;

      setSnackbarMessage(type === 'peer' ? 'Todas as avaliações de pares foram enviadas com sucesso.' : 'Todas as avaliações de liderança foram enviadas com sucesso.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setPendingEvaluations([]);
      type === 'peer' ? setCompletedPeers(true) : setCompletedLeaders(true);
    } catch (error) {
      console.error('Error submitting evaluations:', error);
      setSnackbarMessage(type === 'peer' ? 'Erro ao enviar as avaliações de pares.' : 'Erro ao enviar as avaliações de liderança.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const requiredQuestions = type === 'peer'
      ? ['teamRelationship', 'leadershipRelationship', 'technicalKnowledge', 'taskEfficiency', 'taskResponsibility', 'companyCommitment', 'teamGrowthContribution', 'comments']
      : ['teamUnity', 'communication', 'problemSolving', 'personalDevelopment', 'technicalCapability', 'transparency', 'comments'];

    const unansweredQuestions = requiredQuestions.filter(question => !answers[question]);

    if (unansweredQuestions.length > 0) {
      setSnackbarMessage('Por favor, responda todas as perguntas obrigatórias.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    if (selectedUser && evaluatorId) {
      const evaluationData = {
        user_id: selectedUser.user_id || null,
        email: selectedUser.email,
        answers: JSON.stringify(answers),
        type: type,
      };

      setPendingEvaluations([...pendingEvaluations, evaluationData]);

      const newCompletedSet = new Set([...completed, selectedUser.user_id || selectedUser.email]);
      setCompleted(newCompletedSet);
      setSelectedUser(null);
      setAnswers({});
      setModalOpen(false);
    }
  };

  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (!isEvaluationPeriodActive) {
    return (
      <Container>
        <Typography variant="h5">O período de avaliação não está ativo.</Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Box display="flex" flexDirection="column" alignItems="center">
        {!selectedUser ? (
          type === 'peer' ? (
            <SelectEvaluatee users={users} onSelect={(user) => { setSelectedUser(user); setModalOpen(true); }} completed={completed} />
          ) : (
            leaders.length > 0 && (
              <SelectLeader leaders={leaders} onSelect={(leader) => { setSelectedUser(leader); setModalOpen(true); }} completed={completed} />
            )
          )
        ) : null}

        <Button
          variant="contained"
          color="primary"
          disabled={completed.size !== (type === 'peer' ? users.length : leaders.length)}
          onClick={handleSubmitEvaluation}
          style={{ marginTop: '20px' }}
        >
          Enviar Todas as Avaliações
        </Button>
      </Box>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <Paper style={{ padding: '20px', maxWidth: '600px', width: '100%', margin: '0 20px' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h6">{selectedUser?.nome}</Typography>
              <IconButton onClick={() => setModalOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <form onSubmit={handleSubmit}>
              {type === 'peer' ? (
                <EvaluationQuestions answers={answers} onChange={handleQuestionChange} />
              ) : (
                <LeadershipEvaluationQuestions answers={answers} onChange={handleQuestionChange} />
              )}
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Salvar Avaliação
              </Button>
            </form>
          </Paper>
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EvaluationForm;
