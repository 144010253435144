import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { supabase } from './supabaseClient';
import EvaluationForm from './components/EvaluationForm';
import EvaluationSelection from './components/EvaluationSelection';
import Login from './components/Login';
import Register from './components/Register';
import Metrics from './components/Metrics';
import UserManagement from './components/UserManagement';
import Navbar from './components/Navbar';
import { Container, Box, Snackbar, Alert } from '@mui/material';
import { CustomThemeProvider } from './theme';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './App.css';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import { EvaluationProvider, useEvaluation } from './contexts/EvaluationContext';

const App: React.FC = () => {
  return (
    <CustomThemeProvider>
      <EvaluationProvider>
        <Router>
          <MainApp />
        </Router>
      </EvaluationProvider>
    </CustomThemeProvider>
  );
};

const MainApp: React.FC = () => {
  const [session, setSession] = useState<any>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const navigate = useNavigate();
  const location = useLocation();
  const { setCompletedPeers, setCompletedLeaders } = useEvaluation();

  useEffect(() => {
    const fetchSession = async () => {
      setLoading(true);
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.error('Erro ao buscar sessão:', error);
        setSnackbarMessage('Erro ao buscar sessão.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setLoading(false);
        return;
      }
      setSession(data?.session);

      if (data?.session) {
        const { data: userData, error: userError } = await supabase
          .from('app_users')
          .select('role, completed_peer_evaluation, completed_leader_evaluation')
          .eq('user_id', data.session.user.id)
          .single();

        if (userError) {
          console.error('Erro ao buscar função do usuário:', userError);
          setSnackbarMessage('Erro ao buscar função do usuário.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setLoading(false);
          return;
        }
        console.log('Dados do usuário:', userData);
        setIsAdmin(userData.role === 'admin');
        setCompletedPeers(userData.completed_peer_evaluation);
        setCompletedLeaders(userData.completed_leader_evaluation);
      }
      setLoading(false);
    };

    fetchSession();

    const { data: authListener } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      if (session?.user) {
        supabase
          .from('app_users')
          .select('role, completed_peer_evaluation, completed_leader_evaluation')
          .eq('user_id', session.user.id)
          .single()
          .then(({ data, error }: { data: any; error: any }) => {
            if (error) {
              console.error('Erro ao buscar função do usuário após mudança de autenticação:', error);
              setSnackbarMessage('Erro ao buscar função do usuário após mudança de autenticação.');
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
            } else {
              console.log('Dados do usuário após mudança de autenticação:', data);
              setIsAdmin(data?.role === 'admin');
              setCompletedPeers(data.completed_peer_evaluation);
              setCompletedLeaders(data.completed_leader_evaluation);
            }
          });
      }
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [setCompletedPeers, setCompletedLeaders]);

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Erro ao sair:', error);
      setSnackbarMessage('Erro ao sair.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else {
      setSession(null);
      setIsAdmin(false);
      navigate('/login');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <Navbar session={session} isAdmin={isAdmin} onLogout={handleLogout} />
      <Box mt={2}>
        <TransitionGroup component={null}>
          <CSSTransition key={location.key} classNames="fade" timeout={300}>
            <Routes location={location}>
              {session ? (
                <>
                  <Route path="/" element={<EvaluationSelection />} />
                  <Route path="/evaluation/:type" element={<EvaluationForm />} />
                  <Route path="/metrics" element={isAdmin ? <Metrics /> : <Navigate to="/" />} />
                  <Route path="/user-management" element={isAdmin ? <UserManagement /> : <Navigate to="/" />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </>
              ) : (
                <>
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="*" element={<Navigate to="/login" />} />
                </>
              )}
            </Routes>
          </CSSTransition>
        </TransitionGroup>
      </Box>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default App;
